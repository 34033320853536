.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.errors{
  color: #aa5757;
  text-align: left;
}

.position-absolute {
  position: absolute !important;
}

.sign__content .form__pass {
  position: relative;
}

.sign__content .fa-eye, .sign__content .fa-eye-slash {
  cursor: pointer;
  top: 100px;
  right: 18px;
  color: #848484;
  font-size: 20px;
}

.techwave_fn_sign label {
  text-align: left;
}

.signup .fa-eye, .signup .fa-eye-slash {
  top: 60px;
}
.disp-none {
  display: none!important;
}

.contact-message {
  color: blue;
}

.reset-pass .fa-eye, .reset-pass .fa-eye-slash {
  top: 60px;
}
.text-center{
  text-align:center;
}

.fn__gallery_items{
  height: auto !important;
}

.support_page .modern {
  max-width: 100% !important;
  margin: 0 50px;
}

.pl_btn {
  position: absolute;
  right: 50px;
}

.panel-opened .noti-c a:after{
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #f1416c;
  left: 50%;
  bottom: 50%;
  margin-left: 2px;
  margin-bottom: 2px;
  border-radius: 100%;
}

.gen-image {
border-radius: 30px;
padding: 10px;
max-width: 350px;
}
.fn__chatbot .bot__chat .chat {
  margin-bottom: 10px;
}

.edit-btn {
  position: absolute;
  margin-top: 30px;
  margin-left: -50px;
  color:white;
  font-size: 30px;
}

.download-btn {
  position: absolute;
  margin-top: 30px;
  margin-left: -100px;
  color:white;
  font-size: 30px;
}

.fn__nav_bar .user_opener {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.techwave_fn_header {
  padding: 0 30px;
}


#palleon-user-menu img {
  width: 40px!important;
  height: 40px!important;
  border-radius: 50%!important;
  object-fit: cover;
}

.section_home .section_left_home {
  width: 100%;
}

.fn__quantity #variation {
  min-width: 100%;
}

.fn__upload.has_img {
  width: 100%;
  padding: 0px;
  border: none;
  aspect-ratio: 1/0.5;
}

.techwave_fn_user_settings .services_left {
  width: 300px;
  max-width: 30% !important;
}
.techwave_fn_user_settings .services_right {
  max-width: 70% !important;
}

.fn__upload {
  width: 50%;
  aspect-ratio: auto;
}

.services_left .item {
  padding-bottom: 15px;
}

.fn__upload .fn__close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
}
.fn__upload .fn__download {
  position: absolute;
  right: 55px;
  top: 20px;
  z-index: 3;
}